import { Component, Inject, OnDestroy } from '@angular/core';
import { StepperModule } from 'primeng/stepper';
import { FileDropComponent } from '../../../@shared/components/file-drop/file-drop.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FileDropTypes } from '../../../@shared/components/file-drop/file-drop.types';
import { ContractService } from '../../services/contracts.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../../@core/services/toast.service';

@Component({
  selector: 'app-create-contract',
  standalone: true,
  imports: [StepperModule, FileDropComponent, ReactiveFormsModule, MatDialogModule, ButtonModule],
  templateUrl: './create-contract.component.html',
  styleUrl: './create-contract.component.scss'
})
export class CreateContractComponent {

  fileData: File = new File([''], 'filename');
  myForm!: FormGroup;
  message: string = '';
  isFileAdded: boolean = false;
  htmlContent: string = '';
  public allowedFileTypes: string[] = [FileDropTypes.PDF, FileDropTypes.DOC];

  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { contractId: string },
    private contractService: ContractService,
    public dialogRef: MatDialogRef<CreateContractComponent>,
    private toastService: ToastService
  ) { }

  onFileUploaded(file: File | File[]) {
    if (Array.isArray(file) && file.length == 1) {
      const fileExtension = file[0].name.split('.').pop()?.toLocaleLowerCase();
      if (this.allowedFileTypes.includes('.' + fileExtension)) {
        this.fileData = file[0];
        this.isFileAdded = true;
      } else {
        this.message = 'Please upload Only .docx and .pdf File';
        alert(this.message);
      }
    }else if(Array.isArray(file) && file.length > 1){
      this.toastService.error('Please upload single file')
    }
  }

  createContract() {
    this.loading = true
    let form: FormData = new FormData();
    const fileName = this.fileData.name.split('.').shift() as string;
    form.append('name', fileName)
    form.append('id', this.data.contractId)
    form.append('file', this.fileData)

    this.contractService.uploadContract(form).subscribe({
      next: (res) => {
        this.loading = false
        if (res.success && res.data) {
          this.dialogRef.close(res.data);
        }
      },
      error: _ => this.loading = false
    })
  }


}

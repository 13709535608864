import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../../@core/services/base.service';
import { environment } from '../../../../../../environments/environment.dev';

export enum DOCUMENT_MODEL {
    CONTRACT = 'Contract',
    REPOSITORY = 'File',
    VERSION = 'Version'
}

@Injectable({
    providedIn: 'root'
})

export class CompareService extends BaseService {

    private url = environment.apiUrl + '/compare'
    constructor(
        protected override http: HttpClient,
        protected ngZone: NgZone
    ) {
        super(http, ngZone);
    }

    getCompareResult(data: { document: string, compareDocument: string, docModel: DOCUMENT_MODEL }) {
        return this.post<any>(this.url + '/compare-docs', data)
    }
}

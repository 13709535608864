import { inject } from '@angular/core';
import { HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { IUser } from '../models/model';
import { SocketService } from '../services/socket.service';

export const JwtInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const authService = inject(AuthenticationService);
    const socketService = inject(SocketService);
    const currentUser: IUser | null | undefined = authService.currentUserSubject.value;
    let token = localStorage.getItem('token');
    let socketId = socketService.getSocketId();
    let refreshToken = localStorage.getItem('refreshToken');

    if (request.url.indexOf('refreshToken') !== -1) {
        return next(request)
    }
    if (currentUser) {
        const headers: Record<string, string> = {
            Authorization: `Bearer ${token}`
        };

        const params: Record<string, string> = {
            socketId: socketId || '',
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        const _req = request.clone({
            setHeaders: headers,
            setParams: params
        });

        return next(_req);
    }


    return next(request)
}
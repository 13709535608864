import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ContractService } from '../../services/contracts.service';
import { CONTRACT_TYPE, IContract } from '../../models/contract-management.model';
import { ToastService } from '../../../@core/services/toast.service';
import { SelectionDialogComponent } from "../../../@shared/components/selection-dialog/selection-dialog.component";
import { ILinkDocument, IUser, SHARE_ACCESS } from '../../../@core/models/model';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { CommonModule } from '@angular/common';
import { FullNamePipe } from '../../../@shared/pipes/fullName.pipe';
import { UserAvatarPipe } from '../../../@shared/pipes/user-avatar-pipe';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { AiAssistantComponent } from "../../../@shared/components/ai-assistant/ai-assistant.component";
import { LinkDocumentService } from '../../services/link-document.service';
import { BadgeComponent } from '../../../@shared/components/badge/badge.component';
import { MarkdownModule } from "ngx-markdown";

@Component({
  selector: 'app-link-file',
  standalone: true,
  imports: [ButtonModule, DialogModule, DropdownModule, ReactiveFormsModule, FormsModule, SelectionDialogComponent, CommonModule, FullNamePipe, UserAvatarPipe, MenuModule, AiAssistantComponent, BadgeComponent, MarkdownModule],
  templateUrl: './link-file.component.html',
  styleUrl: './link-file.component.scss'
})

export class LinkFileComponent implements OnInit {
  @Input() document!: IContract;

  currentUser: IUser | null | undefined;
  documents: string[] | undefined = [];
  selectedDocument: string | undefined;
  linkDocs: ILinkDocument[] = [];
  linkDocMenuItems: MenuItem[] | undefined;

  isExistingDocOpen = false;
  isExistingDocLoading = false;
  isDocsLoading = false;
  existingDocuments: (IContract & { selected?: boolean })[] = [];
  newExistingDocs: (IContract & { selected?: boolean })[] = [];
  isCreateDocOpen = false;

  //for infinite scroll
  limit: number = 50
  page: number = 1

  constructor(
    private linkDocumentService: LinkDocumentService,
    private contractService: ContractService,
    private toast: ToastService,
    private authService: AuthenticationService,
    private router: Router,
  ) {
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res;
    });
    this.linkDocMenuItems = [{ label: 'Unlink', icon: 'pi pi-trash' }];
  }

  ngOnInit() {
    this.fetchLinkDocs();
  }

  openExistingDoc() {
    this.isExistingDocOpen = true;
    this.fetchInitialDocs();
  }

  resetExistingDoc() {
    this.isExistingDocOpen = false;
    this.page = 1;
  }

  fetchInitialDocs() {
    const skip = (this.page - 1) * this.limit;
    this.isExistingDocLoading = true;
    this.fetchDocs(skip, this.limit);
  }

  fetchMoreDocs(name: string) {
    this.page++;
    const skip = (this.page - 1) * this.limit;
    this.isDocsLoading = true;
    this.fetchDocs(skip, this.limit, name);
  }

  searchDocs(searchValue: string) {
    this.isDocsLoading = true;
    this.page = 1;
    this.fetchDocs(0, this.limit, searchValue);
  }

  fetchDocs(skip: number, limit: number, name: string = '') {
    const linkedDocIds: string[] = this.linkDocs.map(linkDoc => linkDoc.targetDocument._id);
    linkedDocIds.push(this.document._id);
    this.contractService.getContracts({ type: CONTRACT_TYPE.ALL, skip, limit, name, excludeDocs: linkedDocIds }).subscribe((res) => {
      if (res && res.data) {
        this.isExistingDocLoading = false;
        this.isDocsLoading = false;
        if (skip > 0) {
          this.newExistingDocs = res.data.contracts
        } else {
          this.existingDocuments = res.data.contracts;
        }
      } else {
        this.isExistingDocLoading = false;
        this.isDocsLoading = false;
        this.toast.error("Error fetching all documents");
      }
    })
  }

  fetchLinkDocs() {
    this.linkDocumentService.fetchLinkDocument(this.document._id).subscribe(res => {
      if (res && res.data) {
        this.linkDocs = res.data;
      } else {
        this.toast.error('Error fetching linked documents');
      }
    });
  }

  submitDocSelection(selectedDocuments: (IContract & { selected?: boolean })[]) {
    const selectedDocs = selectedDocuments.map(doc => doc._id).filter(id => id !== this.document._id && !this.linkDocs.some(linkDoc => linkDoc.targetDocument._id === id));
    this.linkDocumentService.linkDocument(this.document._id, selectedDocs, this.currentUser?._id).subscribe(res => {
      if (res && res.success) {
        this.toast.success('Documents Linked successfully');
        this.resetExistingDoc();
        this.fetchLinkDocs();
      } else {
        this.resetExistingDoc();
      }
    });
  }

  handleMenuItemClick(event: Event, documentId: string, item: { label: string, icon: string, route: string[] }) {
    event.stopPropagation();
    if (item.label === 'Unlink') {
      this.deleteLinkDocument(documentId);
    }
  }

  deleteLinkDocument(documentId: string) {
    this.linkDocumentService.deleteLinkDocument(documentId).subscribe(res => {
      if (res && res.success) {
        this.toast.success('Unlinked document successfully');
        this.fetchLinkDocs();
      }
    });
  }

  async openDocument(documentId: string) {
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate(['edit-document'], {
      queryParams: { documentId },
    });
  }

  openCreateDoc() {
    this.isCreateDocOpen = true;
  }

  openAndLinkDoc(documentId: string) {
    this.linkDocumentService.linkDocument(this.document._id, [documentId], this.currentUser?._id).subscribe(res => {
      if (res && res.success) {
        this.isCreateDocOpen = false;
        this.openDocument(documentId)
      } else {
        this.isCreateDocOpen = false;
      }
    });
  }

  handleDialogClose() {
    this.resetExistingDoc();
  }
}
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    standalone: true,
    name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(value: string) {

        let formattedText = value;

        formattedText = formattedText.replace(/\n/g, ' <br> ');

        formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        formattedText = formattedText.replace(/\*(.*?)\*/g, '<em>$1</em>');

        // Jira ticket id
        formattedText = formattedText.replace(/\b(ARN-\d+)\b/g, '<a href="https://inncircles.atlassian.net/browse/$1" target="_blank">$1</a>');

        // formattedText = formattedText.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');

        formattedText = formattedText.replace(/\[\s*([^\]]+?)\s*\]\(\s*(https?:\/\/[^\s]+?)\s*\)/g, function (match, p1, p2) {
            return `<a href="${p2.trim()}" target="_blank">${p1.trim()}</a>`;
        });

        const sourceRegex = /\[SRC(\d+)\](.*?)\[\/SRC\1\]/g;
        formattedText = formattedText.replace(
            sourceRegex,
            (match, sourceNum, text) => {
                const escapedText = text.replace(/"/g, '&quot;');
                return ` <style>
          .source-container {
            position: relative;
            display: inline-block;
        }
        
        .source-text {
            cursor: help;
        }
        
        .overlay {
            visibility: hidden;
            position: absolute;
            z-index: 1;
            top: 100%; /* Position at the bottom of the text */
            left: 50%;
            transform: translateX(-50%);
            background-color: rgba(0,0,0,0.8);
            color: white;
            padding: 10px;
            border-radius: 6px;
            width: 200px;
            opacity: 0;
            transition: opacity 0.3s, visibility 0.3s;
            margin-top: 10px; /* Space between text and tooltip */
        }
        
        .source-container:hover .overlay {
            visibility: visible;
            opacity: 1;
        }
        
        .overlay::before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent rgba(0,0,0,0.8) transparent;
        }
    </style>
                <div class="source-container">
        <span class="source-text rounded-full bg-orange-500 text-white px-2 py-1 text-xs">${sourceNum}</span>
        <div class="overlay">
            ${escapedText}
        </div>
    </div>`;
            }
        );

        return this.sanitizer.bypassSecurityTrustHtml(formattedText);
    }
}
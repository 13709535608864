<p-dialog [(visible)]="visible" [header]="'Header'" [style]="{width: '43vw'}" [modal]="true"
    (onHide)="cancel()" (clickOutside)="cancel()">
    <ng-template pTemplate="header">
        <div class="flex flex-row items-center gap-1 theme-text">
            <span class="mr-2 material-icons-outlined">smart_toy</span>
            <div class="text-lg font-bold">Ask AI Assistant</div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col align-items-center gap-4">

            @if(loading){
            <div class="flex flex-row font-semibold items-center justify-center">
                <span class="theme-text">AI Assistant is thinking</span><span id="dots" class="theme-text">• • •</span>
            </div>
            }

            <textarea pInputTextarea rows="5" class="flex-auto rounded-md ai-text-area" id="aiTextArea"
                [disabled]="loading" (keydown.enter)="callAIAssistant()"
                placeholder="Ask AI Assistant to generate document" [(ngModel)]="content"
                (ngModelChange)="checkWordLimit()"></textarea>

            @if(wordCount > wordLimit) {
            <p class="text-xs text-right text-red-500">Maximum word limit is 500</p>
            }
            @else {
            <p class="text-xs text-right -mt-2">{{ wordCount }} / {{wordLimit}} words</p>
            }

            <div class="flex flex-row w-full my-2 gap-2 overflow-x-auto whitespace-nowrap no-scrollbar">
                @for(question of promptQuestions;track $index) {
                <div class="cursor-pointer theme-border text-xs text-center font-semibold px-2 py-1"
                    [class.pointer-events-none]="loading" (click)="sendPrompt(question)">
                    {{question}}
                </div>
                }
            </div>

            <div class="flex justify-end">
                <p-button label="Send" severity="primary" icon="pi pi-send" iconPos="right"
                    (onClick)="callAIAssistant()" [disabled]="!content.length || loading"
                    styleClass="w-100 bg-arena-orange p-1 px-3 rounded-md text-white text-sm" />
            </div>
        </div>
    </ng-template>
</p-dialog>
<div class="flex items-center gap-2 w-full">
    <span class="font-bold white-space-nowrap">
        Summary
    </span>
    <span
        class="ml-2 p-2 flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium theme-text ring-1 ring-inset ring-blue-700/10">
        <img src="icons/star.svg" class="mr-1.5 h-3 w-3 text-blue-400" alt="Star icon">
        AI Generated
    </span>
</div>
@if (summaryLoading) {
<div class="flex justify-center items-center h-full">
    <p-progressSpinner styleClass="my-4" [style]="{width: '30px', height: '30px'}"></p-progressSpinner>
</div>
} @else{

<div class="flex flex-col justify-center items-center text-sm p-2">
    <!-- @if (contractInsights && !contractInsights.isValidContract) {
    <div class="flex items-center justify-between p-1 w-full my-2 bg-red-100 border border-red-300 rounded">
        <span class="text-red-700 font-bold text-sm">This document may not conform to standard contract
            formats.</span>
        <i class="pi pi-times text-red-700 cursor-pointer"></i>
    </div>
    } -->
    <div class=" flex justify-between items-center text-xs font-bold my-2 w-full ">
        <!-- <div>
            Last Updated : {{$any(contractInsights?.updatedAt) | dateFormatter :'DD/MM/yyyy , HH:MM a'}}
        </div> -->
        <!-- <div (click)="generateSummary(true)"
            class="cursor-pointer flex items-baseline gap-2 text-arena-orange-900  hover:text-arena-orange-500 transition-colors duration-300 rounded-md">
            <i class="pi pi-refresh text-sm"></i>
            <span class="font-semibold"> Refresh</span>
        </div> -->
    </div>
    @if(contractInsights) {
        <markdown class="text-justify" [data]="contractInsights"></markdown>
        <!-- <div  [innerHTML]="contractInsights | formatText" class="text-justify"></div> -->
    }
    <!-- <div  [innerHTML]="contractInsights | formatText" class="text-justify text-container"></div> -->
    <!-- <div class="text-justify"> {{contractInsights?.summary}} </div> -->
  
</div>
}
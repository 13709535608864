import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ContractService } from '../../services/contracts.service';
import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ContractInsightsService } from '../../services/contract-insights.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { IContractInsights } from '../../models/contract-management.model';
import { DateFormatterPipe } from "../../../@shared/pipes/dateFormater.pipe";
import { ContractTagService } from '../../services/contract-tag.service';
import { IGetContractTagMappings } from '../../services/contract-tag.service';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TranslateComponent } from '../translate/translate.component';
import { CheckPointsComponent } from '../check-points/check-points.component';
import { SummaryComponent } from '../summary/summary.component';
import { ContractChecklistService } from '../../services/contract-checklist-item.service';
import { CommonModule } from '@angular/common';
import * as echarts from 'echarts';
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts';
import { GAUGE_COLORS, setGuageChart } from '../../utils';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
import { ToastService } from '../../../@core/services/toast.service';
import { KeyHighlightsComponent } from "./components/key-highlights/key-highlights.component";
import { TruncatePipe } from "../../../@shared/pipes/truncate.pipe";
import { DataTypeComponent } from "./components/key-highlights/components/data-type/data-type.component";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CompareSelectionComponent } from '../edit-contract/components/compare/compare-selection/compare-selection.component';
import { IDocumentType } from '../../models/settings.model';
import { SOCKET_EVENTS, SocketService } from '../../../@core/services/socket.service';
import { Subscription } from 'rxjs';

enum CURRENT_SCREEN_WINDOW {
  DEFAULT = 'DEFAULT',
  SUMMARY = 'SUMMARY',
  TRANSALTE = 'TRANSLATE',
  CHECKPOINTS = 'CHECKPOINTS',
  KEY_HIGHLIGHTS = 'KEY_HIGHLIGHTS',
}

@Component({
  selector: 'app-contract-insights',
  standalone: true,
  imports: [AutoCompleteModule, DropdownModule, AccordionModule, BadgeModule, ProgressSpinnerModule, TranslateComponent, CheckPointsComponent, SummaryComponent, ButtonModule, FormsModule, ButtonModule, CommonModule, NgxEchartsModule,
    ChipModule, TooltipModule, KeyHighlightsComponent, TruncatePipe, DataTypeComponent],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useValue: {
        echarts: () => import('echarts')
      }
    }
  ],
  templateUrl: './contract-insights.component.html',
  styleUrl: './contract-insights.component.scss'
})

export class ContractInsightsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() contractId: string = "";
  @Input() documentType: string | IDocumentType = "";
  contractTypes: any[] | undefined;
  contractInsights: IContractInsights | undefined;
  summaryLoading: boolean = true;
  contractTagMappings: IGetContractTagMappings[] = []
  selectedTagId: string = ''
  tagSuggestions: { name: string, _id: string }[] = []
  editTagTypeId: string = ''
  CURRENT_SCREEN_WINDOW = CURRENT_SCREEN_WINDOW
  currentScreenComponent: string = ''
  checklistCount: number = 0 //Save for later checklists count
  riskScore: number = 0
  chartOption: echarts.EChartsOption = {}
  isRefreshing: boolean = false
  onEditTag: boolean[] = []
  changedValue: string = ''
  tagsLoading: boolean = false
  refreshInProgressSubscription!: Subscription;
  refreshDoneSubscription!: Subscription;
  refreshingUser!: string
  latestInsightDetails: { username: string, updatedAt: Date } | null = null
  documentInSync: boolean = false
  extractEntitiesSubscription: any

  constructor(private contractService: ContractService, private contractInsightsService: ContractInsightsService,
    private contractTagService: ContractTagService, private toast: ToastService,
    private contractChecklistService: ContractChecklistService,
    private socketService: SocketService, private router: Router, private dialog: MatDialog) {
    this.extractEntitiesSubscription = this.socketService.on(SOCKET_EVENTS.CONTRACT_TAG_MAPPING).subscribe((result: any) => {
      this.updateTagMappings(result[0])
    });
  }

  ngOnInit(): void {
    this.getLatestInsightsDetails()
    this.refreshInProgressSubscription = this.socketService.on<{ username: string }>(SOCKET_EVENTS.REFRESH_IN_PROGRESS).subscribe(res => {
      if (res.length) {
        this.isRefreshing = true;
        this.refreshingUser = res[0].username
      }
    })
    this.refreshDoneSubscription = this.socketService.on(SOCKET_EVENTS.REFRESH_DONE).subscribe(res => {
      this.isRefreshing = false;
      this.toast.success("Insights generated successfully");
      this.getLatestInsightsDetails()
      this.updateTagMappings({ mappings: [], documentInSync: false })
      this.getTagMappings()
    })
  }

  ngOnChanges(): void {
    // this.getContractClauses(false)
    this.getTagMappings()
    this.getSaveForLaterChecklistsCount()
    this.getRiskScore()
  }

  ngOnDestroy(): void {
    if (this.refreshInProgressSubscription) {
      this.refreshInProgressSubscription.unsubscribe()
    }
    if (this.refreshDoneSubscription) {
      this.refreshDoneSubscription.unsubscribe()
    }
    if (this.extractEntitiesSubscription) {
      this.extractEntitiesSubscription.unsubscribe()
    }
  }

  generateInsights() {
    this.isRefreshing = true;
    if (this.contractId.length > 0) {
      this.contractInsightsService.generateEmbeddings(this.contractId).subscribe({
        next: (res) => {
        },
        error: (err) => {
          this.toast.error("Failed to generate insights. Please try again.");
          console.error("Error generating embeddings:", err);
          this.isRefreshing = false;
        }
      });
    }
    else {
      console.error("No such contract")
    }
  }


  generateSummary(generateInsights: boolean) {
    if (this.contractId.length > 0) {
      this.summaryLoading = true;
      this.contractInsightsService.generateSummary(this.contractId, generateInsights).subscribe({
        next: (res: any) => {
          if (res.data) {
            this.contractInsights = res.data;
          }
          this.summaryLoading = false;
        },
        error: (error) => {
          this.summaryLoading = false;
        }
      });
    }
  }

  getTagMappings() {
    this.tagsLoading = true
    this.contractTagService.getContractTagMappings(this.contractId).subscribe(res => {
      if (res.success && res.data) {
        this.contractTagMappings = res.data.mappings
        this.documentInSync = res.data.documentInSync
        this.contractTagMappings = this.contractTagMappings.length > 4 ? this.contractTagMappings.slice(0, 4) : this.contractTagMappings
        this.onEditTag = new Array(this.contractTagMappings.length).fill(false)
        this.tagsLoading = false
      }
    })
    this.tagsLoading = false
  }

  updateTagMappings(data: { mappings: IGetContractTagMappings[], documentInSync: boolean }) {
    if (data.documentInSync) {
      this.toast.success('Tags extracted successfully.')
    }
    this.contractTagMappings = data.mappings
    this.documentInSync = data.documentInSync
    this.contractTagMappings = this.contractTagMappings.length > 4 ? this.contractTagMappings.slice(0, 4) : this.contractTagMappings
    this.onEditTag = new Array(this.contractTagMappings.length).fill(false)
    this.tagsLoading = false
  }

  searchTags(event: any, tagTypeId: string) {
    this.contractTagService.getTagsBasedOnSearchValue({ contract: this.contractId, tagType: tagTypeId, searchValue: event.query }).subscribe(res => {
      if (res.success && res.data) {
        this.tagSuggestions = res.data.map((tag: { _id: string; name: string }) => ({
          ...tag,
          name: this.trimLetters(tag.name, 20)
        }));
      }
    })
  }

  trimLetters(text: string, charLimit: number): string {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + '...';
    }
    return text;
  }

  onOptionSelect(event: AutoCompleteSelectEvent) {
    this.selectedTagId = event.value._id
  }

  addTagMapping(tagTypeId: string) {
    this.contractTagService.addContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: this.selectedTagId }).subscribe(res => {
      if (res.success) {
        this.selectedTagId = ''
        this.editTagTypeId = ''
        this.getTagMappings()
      }
    })
  }

  editClicked(tagTypeId: string) {
    this.selectedTagId = ''
    this.editTagTypeId = this.editTagTypeId == tagTypeId ? '' : tagTypeId
    let i = this.contractTagMappings.findIndex(tag => tag.tagTypeId == tagTypeId)
    if (this.contractTagMappings[i].dataType == 'Custom') {
      return
    }
    this.contractTagMappings[i].tags?.forEach(tag => {
      if (tag.aiGenerated || tag.dataType != 'Custom') {
        this.editTagTypeId = ''
      }
    })
    this.onEditTag[i] = !this.onEditTag[i]
  }

  deleteClicked(tagTypeId: string, tagId: string) {
    this.contractTagService.removeContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: tagId }).subscribe(res => {
      if (res.success) {
        this.getTagMappings()
        this.getRiskScore()
      }
    })
  }

  goBack() {
    if (this.currentScreenComponent == CURRENT_SCREEN_WINDOW.CHECKPOINTS) {
      this.getSaveForLaterChecklistsCount()
      this.getRiskScore()
    }
    else if (this.currentScreenComponent == CURRENT_SCREEN_WINDOW.KEY_HIGHLIGHTS) {
      this.getTagMappings()
    }
    this.currentScreenComponent = '';
  }

  openComponent(currentScreen: string) {
    if (currentScreen == CURRENT_SCREEN_WINDOW.CHECKPOINTS) {
      this.contractInsightsService.documentInSync(this.contractId).subscribe(res => {
        if (res.success && res.data) {
          this.currentScreenComponent = currentScreen
        }
        else if (res.success && !res.data) {
          this.toast.info("Tags are being extracted, please check after some time.");
        }
      })
    }
    else
      this.currentScreenComponent = currentScreen
  }

  getSaveForLaterChecklistsCount() {
    this.contractChecklistService.getSaveForLaterChecklistsCount(this.contractId).subscribe(res => {
      if (res.success) {
        this.checklistCount = res.data
      }
    })
  }

  getRiskScore() {
    this.contractService.getContractRiskScore(this.contractId).subscribe(res => {
      if (res.success) {
        this.riskScore = res.data
        this.chartOption = setGuageChart(this.riskScore, [GAUGE_COLORS.GREEN, GAUGE_COLORS.YELLOW, GAUGE_COLORS.RED])
      }
    })
  }

  suggestEdit(tagTypeId: string, tagId: string, close: boolean) {
    if (close) {
      this.tagsLoading = true
      this.editTagTypeId = ''
      this.changedValue = ''
      this.getTagMappings()
      return
    }
    this.tagsLoading = true
    if (this.changedValue == '') {
      this.tagsLoading = false
      return
    }
    this.contractTagService.addAItagToContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: tagId, tagName: this.changedValue }).subscribe(res => {
      if (res.success) {
        this.getTagMappings()
      }
    })
  }

  openCompareDialog() {
    const _dialogRef = this.dialog.open(CompareSelectionComponent, {
      disableClose: true,
      width: '40vw',
      maxHeight: '80vh',
      data: {
        contractId: this.contractId,
        documentType: this.documentType
      }
    });
    _dialogRef.afterClosed().subscribe(doc => {
      if (doc) {
        this.router.navigate(['/compare'], {
          queryParams: {
            documentId: this.contractId,
            comparedDocumentId: doc.contract._id,
            documentType: this.documentType,
            compareType: doc.compareType,
          }
        })
      }
    })
  }

  getLatestInsightsDetails() {
    this.contractService.getLatestInsightsDetails(this.contractId).subscribe(res => {
      if (res.success) {
        if (res.data.inProgressJob) {
          this.isRefreshing = true
          this.refreshingUser = res.data.inProgressJob.username
        }
        else if (res.data.latestInsights) {
          this.latestInsightDetails = res.data.latestInsights
        }
      }
    })
  }

}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { TruncatePipe } from '../../../../../../../@shared/pipes/truncate.pipe';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';

export enum TAG_DATA_TYPES {
  TEXT = 'Text',
  NUMBER = 'Number',
  DATE = 'Date',
  CUSTOM = 'Custom'
}


@Component({
  selector: 'app-data-type',
  standalone: true,
  imports: [FormsModule, CommonModule, TooltipModule, TruncatePipe, CalendarModule, InputNumberModule],
  templateUrl: './data-type.component.html',
  styleUrl: './data-type.component.scss'
})
export class DataTypeComponent implements OnChanges {

  @Input() dataType: string = '';
  @Input() value: any = '';
  @Input() onEdit: boolean = false;


  @Output() valueChange = new EventEmitter<string>();
  @Output() onValueChange = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter<boolean>();

  TAG_DATA_TYPES = TAG_DATA_TYPES;

  constructor() { }


  ngOnChanges() {
    this.setUpData()
  }

  valueChanged() {
    this.onValueChange.emit(this.value)
  }

  deleteTag() {
    this.onDelete.emit(true)
  }
  setUpData() {
    switch (this.dataType) {
      case TAG_DATA_TYPES.DATE:
        this.value = new Date(this.value);
        break;
      default:
        this.value = this.value
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { IConfig , DocumentEditorModule} from '@onlyoffice/document-editor-angular';
import { IUser } from '../../../@core/models/model';
import { environment } from '../../../../environments/environment.dev';

@Component({
  selector: 'app-only-office-doc-editor',
  standalone: true,
  imports: [DocumentEditorModule],
  templateUrl: './only-office-doc-editor.component.html',
  styleUrl: './only-office-doc-editor.component.scss'
})
export class OnlyOfficeDocEditorComponent implements OnInit {
  @Input() contractId: string = '';
  @Input() user !: IUser;
  config!: IConfig;
  @Input()
  set configData(config: IConfig) {
    this.config = config;
  }
  @Input() edit: boolean = false;
  // @Input() comments: boolean = true;
  @Input() chat: boolean = true;
  @Input() download: boolean = true;
  public onlyOfficeServer = environment.onlyOfficeServer;

  ngOnInit(): void {
    // const permissions = {
    //   chat: this.chat,
    //   edit: this.edit,
    //   comment: this.comments,
    //   download: this.download
    // };
    // if(this.config.document != undefined) {
    //   this.config.document['permissions'] = permissions;
    // }
  }
 
  onDocumentReady = () => {
    console.log("Document is loaded")
  }
  onLoadComponentError = (errorCode: any, errorDescription: any) => {
    switch (errorCode) {
      case -1: // Unknown error loading component
        console.log(errorDescription)
        break
      case -2: // Error load DocsAPI from http://documentserver/
        console.log(errorDescription)
        break
      case -3: // DocsAPI is not defined
        console.log(errorDescription)
        break
    }
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, interval, switchMap } from 'rxjs';
import { BaseService } from '../../@core/services/base.service';
import { environment } from '../../../environments/environment.dev';
@Injectable({
    providedIn: 'root'
})
export class AiChatService extends BaseService {
    private url = environment.apiUrl + '/ai-chat'
    constructor(
        protected override http: HttpClient,
        protected ngZone: NgZone
    ) {
        super(http, ngZone);
    }

    fetchStreamedResponse(prompt: string, contractId: string, compareDocumentId?: string, docModel?: string) {
        return this.stream<string>(this.url + '/conversation', { prompt, contractId, compareDocumentId, docModel })
    }
    getResponse(prompt: string) {
        return this.get<string>(this.url, { prompt })
    }
    fecthSuggestions() {
        return this.get<string[]>(this.url + '/suggestions', {})
    }
    getResponseFromSuggestion(suggestion: string) {
        return this.get<string>(this.url + '/suggestion', { suggestion })
    }
    getHistory(userId: string) {
        return this.get<string[]>(this.url + '/history', { userId })

    }
    getAllConversations(userId: string, conversationId: string) {
        return this.get<string[]>(this.url + '/conversations', { userId, conversationId })
    }
}
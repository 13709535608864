import { Component, OnInit } from "@angular/core";
import { ContractEditorComponent } from "../../../../../../@shared/components/contract-editor/contract-editor.component";
import { ContractEditorService, DOCUMENT_MODEL } from "../../../../../../@shared/components/contract-editor/contract-editor.service";
import { OnlyOfficeDocEditorComponent } from "../../../../../../@shared/components/only-office-doc-editor/only-office-doc-editor.component";
import { IUser } from "../../../../../../@core/models/model";
import { AuthenticationService } from "../../../../../../@core/services/authentication.service";
import { ActivatedRoute } from "@angular/router";
import { SanitizeHtmlPipe } from "../../../../../../@shared/pipes/sanitizeHtml.pipe";
import { SummaryComponent } from "../../../../summary/summary.component";
import { CheckPointsComponent } from "../../../../check-points/check-points.component";
import { MarkdownModule } from "ngx-markdown";
import { FormatTextPipe } from "../../../../../../@shared/pipes/formated-text.pipe";
import { ContractService } from "../../../../../services/contracts.service";
import { IContract } from "../../../../../models/contract-management.model";
import { CommonModule, DatePipe } from "@angular/common";
import { TruncatePipe } from "../../../../../../@shared/pipes/truncate.pipe";
import { BadgeComponent } from "../../../../../../@shared/components/badge/badge.component";
import { CompareSelectionComponent } from "../compare-selection/compare-selection.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SkeletonModule } from "primeng/skeleton";
import { ChatComponent } from "../../../../chat/chat.component";
import { CompareService } from "../compare.service";


@Component({
    selector: 'app-compare-view',
    standalone: true,
    imports: [
        ContractEditorComponent,
        OnlyOfficeDocEditorComponent,
        SanitizeHtmlPipe,
        SummaryComponent,
        CheckPointsComponent,
        MarkdownModule,
        FormatTextPipe,
        DatePipe,
        TruncatePipe,
        BadgeComponent,
        CommonModule,
        SkeletonModule,
        ChatComponent
    ],
    templateUrl: './compare-view.component.html',
    styleUrl: './compare-view.component.scss',
    providers: []
})
export class CompareViewComponent implements OnInit {

    loading = true;
    compareResult: string = '';

    currentUser!: IUser | null | undefined
    documentId!: string
    comparedDocumentId!: string
    documentType!: string
    document!: IContract & { summaryFile: string }
    comparedDocument!: IContract & { summaryFile: string }
    isExpanded = false;
    compareType!: DOCUMENT_MODEL;

    suggestions: string[] = [
        'At what percentages the Daily/Weekly/Monthly rates changed ',
        'What is the highest rate increase in terms of monetary value and percentage for a specific item ?',
        'List the items that faced a rate decrease',
        'Which items had no change in their rates ?',
        'Which is the best quote among these quotes ?',
        'Compare the rates in the tables and give a synopsis'
    ]

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        private compareService: CompareService,
        private contractsService: ContractService
    ) {
        this.authService.currentUser.subscribe(res => {
            this.currentUser = res
        });
        this.route.queryParamMap.subscribe(params => {
            this.documentId = params.get('documentId') as string;
            this.comparedDocumentId = params.get('comparedDocumentId') as string;
            this.compareType = params.get('compareType') as DOCUMENT_MODEL;
            this.documentType = params.get('documentType') as string;
        });
    }

    ngOnInit() {
        this.loading = true;
        this.contractsService.getCompareDocuments({ document: this.documentId, compareDocument: this.comparedDocumentId, compareType: this.compareType }).subscribe((res) => {
            if (res && res.success) {
                this.document = res.data.document;
                this.comparedDocument = res.data.compareDocument;
                this.getCompareResult();
            } else {
                this.compareResult = 'No comparison result found';
                this.loading = false;
            }
        });
    }

    getCompareResult() {
        this.compareService.getCompareResult({ document: this.documentId, compareDocument: this.comparedDocumentId, docModel: this.compareType }).subscribe((res) => {
            if (res && res.success) {
                this.compareResult = res.data;
                this.loading = false;
            } else {
                this.compareResult = 'No comparison result found';
                this.loading = false;
            }
        })
    }

    changeCompareDocument() {
        const _dialogRef = this.dialog.open(CompareSelectionComponent, {
            disableClose: true,
            width: '40vw',
            maxHeight: '80vh',
            data: {
                contractId: this.documentId,
                documentType: this.documentType
            }
        });
        _dialogRef.afterClosed().subscribe(doc => {
            if (doc) {
                this.comparedDocumentId = doc._id as string;
                this.ngOnInit();
            }
        })
    }

}
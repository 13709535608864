import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment.dev';
import { BaseService } from "../../@core/services/base.service";
import { IContract } from "../models/contract-management.model";




@Injectable({ providedIn: 'root' })
export class ContractInsightsService extends BaseService {


    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }


    apiUrl = environment.apiUrl + '/contract-management';

    generateSummary(contractId: string, generateInsights: boolean) {
        return this.get<IContract>(this.apiUrl + '/generate-summary/' + contractId, { generateInsights })
    }


    generateClauses(contractId: string, generateClauses: boolean) {
        return this.get<IContract>(this.apiUrl + '/generate-clauses/' + contractId, { generateClauses })
    }

    generateEmbeddings(contractId: string) {
        return this.get(this.apiUrl + '/generate-embeddings/' + contractId)
    }

    extractAllTags(contractId: string) {
        return this.get<IContract>(this.apiUrl + '/extract-all-tags/' + contractId)
    }

    documentInSync(contractId: string) {
        return this.get<boolean>(this.apiUrl + '/document-in-sync/' + contractId)
    }

}

<div class="inn-flex-container flex flex-col justify-center h-full">
    @if(!linkDocs || linkDocs.length === 0) {
    <div class="flex flex-col justify-center items-center gap-2 p-2" style="margin-top: 10%">
        <div class="link-file">
            <img src="images/link-files.png" alt="link" class="relative z-10">
            <div class="vector-left">
                <img src="/images/orange-top.svg" alt="">
            </div>
            <div class="vector-right">
                <img src="/images/blue-bottom.svg" alt="">
            </div>
        </div>
        <div>
            <h2 class="font-semibold text-base text-center">Link Documents</h2>
            <p class="info text-sm">You can connect documents which are relevant to each other</p>
        </div>
        <div class="flex flex-col gap-2 w-full">
            <p-button (onClick)="openCreateDoc()" label="Create Document to Link"
                styleClass="bg-arena-orange p-3 rounded-xl w-full text-white"></p-button>
            <p-button (onClick)="openExistingDoc()" label="Link Existing Documents"
                styleClass="p-3 rounded-xl w-full text-black border border-gray-400"></p-button>
        </div>
    </div>
    } @else {
    <div class="flex flex-col h-full">
        <div class="list">
            @for (doc of linkDocs; track doc._id) {
            <div class="flex flex-col p-4 border rounded-2xl shadow-sm m-2 gap-y-1 cursor-pointer text-sm"
                (click)="openDocument(doc.targetDocument._id)">
                <div class="flex justify-between">
                    <div class="flex flex-1  justify-between text-start pr-2">
                        <div class="font-semibold text-gray-900">{{ doc.targetDocument.name }}</div>
                        <app-badge [contract]="doc.targetDocument" />
                    </div>
                    <div>
                        <p-menu class="mt-5 menu-container" #menu [model]="linkDocMenuItems" [popup]="true">
                            <ng-template pTemplate="item" let-item>
                                <a class="d-flex align-items-center py-2 px-3 cursor-pointer"
                                    (click)="handleMenuItemClick($event, doc._id, item)">
                                    <span [ngClass]="[item.icon, item.label === 'Unlink' ? 'danger' : '']"></span>
                                    <span class="ml-2"
                                        [ngStyle]="{ 'color': item.label == 'Unlink' ? 'red' : 'inherit' }">{{
                                        item.label }}</span>
                                </a>
                            </ng-template>
                        </p-menu>
                    </div>
                    <div class="cursor-pointer" (click)="menu.toggle($event); $event.stopPropagation()">
                        <i class="pi pi-ellipsis-v"></i>
                    </div>
                </div>
                @if(doc.targetDocument.summary) {
                <div class="flex text-sm text-gray-500">
                    <markdown class="text-justify truncate-summary" [data]="doc.targetDocument.summary"></markdown>
                </div>
                } @else {
                <div class="flex text-sm text-gray-500">No summary available</div>
                }
                <div class="flex items-center mt-2">
                    <img [src]="doc.targetDocument.createdBy| userAvatar" class="rounded-lg" alt="Profile Picture"
                        width="20" />
                    <div class="text-sm text-gray-900 ml-2">{{doc.targetDocument.createdBy | fullName}}</div>
                </div>
            </div>
            }
        </div>
        <div class="buttons">
            <div class="flex flex-col gap-2 w-full">
                <p-button (onClick)="openCreateDoc()" label="Create Document to Link"
                    styleClass="bg-arena-orange p-3 rounded-xl w-full text-white"></p-button>
                <p-button (onClick)="openExistingDoc()" label="Link Existing Documents"
                    styleClass="p-3 rounded-xl w-full text-black border border-gray-400"></p-button>
            </div>
        </div>
    </div>
    }
</div>

<app-ai-assistant [(visible)]="isCreateDocOpen" (onGenerate)="openAndLinkDoc($event)"></app-ai-assistant>


<app-selection-dialog [(visible)]="isExistingDocOpen" [isLoading]="isExistingDocLoading"
    [title]="'Link Existing Document'" [selectionType]="'Documents'" [label]="'Link Selected'"
    [data]="existingDocuments" [infiniteScroll]="true" [newData]="newExistingDocs" [isItemsLoading]="isDocsLoading"
    (onSelectionSubmit)="submitDocSelection($event)" (onDialogClose)="handleDialogClose()"
    (onScrollEndReached)="fetchMoreDocs($event)" (onSearchItem)="searchDocs($event)"></app-selection-dialog>
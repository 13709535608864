import { Component, Input } from '@angular/core';
import { ElementRef, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChatMessage, CONTENT_TYPE, SENDER_TYPE } from './chat.types';
import { AiChatService } from '../../services/aichat.service';
import { SVG_ICONS } from '../../constants/constant';
import { AiChatBubbleComponent } from './components/ai-chat-bubble/ai-chat-bubble.component';
import { SanitizeHtmlPipe } from '../../../@shared/pipes/sanitizeHtml.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastService } from '../../../@core/services/toast.service';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [CommonModule, FormsModule, AiChatBubbleComponent, SanitizeHtmlPipe],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent {
  @Input({ required: true }) contractId!: string;
  @Input() compareDocumentId!: string;
  @Input() compare: boolean = false;
  @Input() docModel!: string
  @Input() suggestions!: string[]
  public icons = SVG_ICONS;
  public maxPromptLength: number = 300
  public promptinput: string = ''
  public promptLength: number = 0
  public disabled: boolean = false

  public promptQuestions = [
    'What are all the clauses in this document?',
    'How far is the term of the contract?',
    'What is dispute clauses and dispute resolution in this document?',
    'What are payment terms in this document?'
  ]
  public inputPrompt: string = ''
  public prompt: string = ''
  public showSuggestions: boolean = true;
  public loading: boolean = false;
  public conversations: ChatMessage[] = [];


  @ViewChild('scrollBottom', { static: true })
  private scrollBottom!: ElementRef;
  constructor(
    private aiChatbotService: AiChatService,
    private toast: ToastService,
    // private notificationService: NotificationService,
    @Optional() public dialogRef: MatDialogRef<ChatComponent>,
  ) { }
  ngOnInit(): void {
    this.getSuggestions();
    // this.getStreamedReponseTest()
  }
  getStreamedReponseTest() {
    this.aiChatbotService.fetchStreamedResponse('test', this.contractId).subscribe(data => console.log(data))
    this.scrollToBottom();
  }
  getSuggestions() {
    if (this.suggestions) {
      this.promptQuestions = this.suggestions;
    }
    // this.aiChatbotService.fecthSuggestions().subscribe(res => {
    //   if(res && res.data){
    //     this.promptSuggestions = res.data;
    //   }
    // });
  }
  suggestionClick(suggestion: string) {
    this.showSuggestions = false;
    this.conversations.push({
      content: suggestion,
      contentType: CONTENT_TYPE.TEXT,
      timestamp: new Date().toISOString(),
      sender: SENDER_TYPE.USER,
      isLoading: false,
    });
    this.loading = true;
    this.conversations.push(
      {
        content: '',
        contentType: CONTENT_TYPE.TEXT,
        timestamp: new Date().toISOString(),
        sender: SENDER_TYPE.AI,
        isLoading: true,
      }
    );
    this.aiChatbotService.fetchStreamedResponse(suggestion, this.contractId, this.compareDocumentId, this.docModel).subscribe((res: any) => {
      if (res && res.data) {
        let data = JSON.parse(res.data)
        if (data.status.includes('[ERR]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = 'Something went wrong, please try again';
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        }
        else if (data.status.includes('[DONE]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        }
        else if (data.content.message.includes('[JIRA]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].contentType = CONTENT_TYPE.TICKET;
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.loading = true;
          this.disabled = true;
        }
        else {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.loading = true;
          this.disabled = true;
        }
      }
    })
  }
  getResponse(value: string) {
    this.showSuggestions = false;
    this.conversations.push({ content: value, contentType: CONTENT_TYPE.TEXT, timestamp: new Date().toISOString(), sender: SENDER_TYPE.USER, isLoading: false });
    this.loading = true;
    this.conversations.push({ content: '', contentType: CONTENT_TYPE.TEXT, timestamp: new Date().toISOString(), sender: SENDER_TYPE.AI, isLoading: true });
    this.aiChatbotService.fetchStreamedResponse(value, this.contractId, this.compareDocumentId, this.docModel).subscribe((res: any) => {
      if (res) {
        let data = res
        this.loading = true;
        if (data.status.includes('[DONE]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        }
        else if (data.status.includes('[ERR]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = 'Something went wrong, please try again';
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        } else {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.disabled = true;
        }
      }
    })
  }
  checkPromptLength() {
    this.promptLength = this.prompt.length;
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.scrollBottom.nativeElement.scrollTop = this.scrollBottom.nativeElement.scrollHeight;
      }, 100)
    } catch (err) {
    }
  }
  sendPrompt() {
    if (this.prompt.length > 0) {
      this.loading = true;
      this.promptinput = this.prompt;
      this.prompt = '';
      this.checkPromptLength();
      this.disabled = true;
      this.getResponse(this.promptinput);
      this.scrollToBottom();
    }
  }

}

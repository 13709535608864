import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../../@core/services/base.service";
import { environment } from "../../../environments/environment.dev";
import { IDocumentVersion } from "../../@core/models/model";


@Injectable({ providedIn: 'root' })
export class VersionService extends BaseService {

    constructor(
        protected override http: HttpClient,
    ) {
        super(http);
    }

    apiUrl = environment.apiUrl + '/document-version';

    getVersions(contractId: string) {
        return this.get<IDocumentVersion[]>(this.apiUrl + '/' + contractId)
    }

    getVersion(contractId: string, versionId: string) {
        return this.get<IDocumentVersion>(this.apiUrl + '/' + contractId + '/' + versionId)
    }
    getDocVersionURL(contract: string, versionId: string) {
        return this.get<{ url: string, key: string }>(this.apiUrl + '/doc-url/' + contract + '/' + versionId);
    }

    restoreVersion(contract: string, versionId: string) {
        return this.post(this.apiUrl + '/restore/' + contract + '/' + versionId, {})
    }

    downloadVersion(contract: string, versionId: string) {
        return this.get<{ data: Uint8Array }>(this.apiUrl + '/download/' + versionId + '/' + contract)
    }

    saveVersion(contract: string) {
        return this.post(this.apiUrl, { contract })
    }

    deleteVersion(contract: string, versionId: string) {
        return this.delete(this.apiUrl + '/' + contract + '/' + versionId)
    }




}
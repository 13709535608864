import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BackComponent } from "./components/back/back.component";
import { WebsocketProvider } from 'y-websocket';
import { CommonModule } from '@angular/common';
import { OnlyOfficeDocEditorComponent } from "../../../@shared/components/only-office-doc-editor/only-office-doc-editor.component";
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from '../../services/contracts.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { VersionService } from '../../services/version.service';
import { ToastService } from '../../../@core/services/toast.service';
import { IConfig } from '@onlyoffice/document-editor-angular';
import { JWTService } from '../../services/jwt.service';
import { IAttachment, IDocumentVersion, IUser } from '../../../@core/models/model';
import { BehaviorSubject } from 'rxjs';
import { IContract } from '../../models/contract-management.model';
import { environment } from '../../../../environments/environment.dev';
import { UserAvatarPipe } from "../../../@shared/pipes/user-avatar-pipe";
import { FullNamePipe } from "../../../@shared/pipes/fullName.pipe";
import { MenuModule } from 'primeng/menu';

@Component({
  selector: 'app-versions',
  standalone: true,
  imports: [CommonModule, BackComponent, OnlyOfficeDocEditorComponent, UserAvatarPipe, FullNamePipe, MenuModule],
  templateUrl: './versions.component.html',
  styleUrl: './versions.component.scss'
})
export class VersionsComponent implements OnInit, OnDestroy {
  public contractId: string = '';
  public headings: any[] = [];
  bottomBarVisible: boolean = false;
  contract !: IContract;
  versions: IDocumentVersion[] = [];
  filteredVersions: IDocumentVersion[] = [];
  selectedVersion!: IDocumentVersion

  currentVersion!: IDocumentVersion

  config: IConfig = {}
  private jwtService = new JWTService();
  connectedUsers$!: BehaviorSubject<IUser[]>
  currentUser!: IUser | null | undefined
  isAnonymousUser: boolean = false
  docFormat: string = 'docx'
  versionLoading: boolean = false;
  permissions: {
    canView: boolean,
    canEdit: boolean,
    canComment: boolean,
    restricted: boolean
  } = {
      canView: false,
      canEdit: false,
      canComment: false,
      restricted: true
    }

  items = [
    {
      label: 'Restore Version',
      icon: 'pi pi-refresh',
      command: () => {
        this.versionService.restoreVersion(this.contract._id, this.selectedVersion.version).subscribe(res => {
          if (res.success) {
            this.toast.success('Version Restored Successfully')
            this.getVersions()
            this.fetchContract()
            this.fetchContractURL()
            this.selectedVersion = this.currentVersion
          }
        })
      }
    },
    {
      label: 'Download',
      icon: 'pi pi-download',
      command: () => {
        this.downloadVersion(this.selectedVersion.version, this.versions.length - this.filteredVersions.indexOf(this.selectedVersion))
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        this.deleteVersion(this.selectedVersion.version)
      }
    }
  ]

  constructor(private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private router: Router, private contractService: ContractService,
    private userService: UserService,
    private authService: AuthenticationService,
    private toast: ToastService,
    private versionService: VersionService,
  ) {

    this.route.queryParamMap.subscribe(params => {
      this.contractId = params.get('documentId') as string ?? ""
    });
    this.connectedUsers$ = this.contractService.connectedUsers$
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res
      this.isAnonymousUser = res?.anonymous ?? false;
    });


  }

  ngOnInit(): void {
    this.fetchContract()
  }

  ngOnDestroy(): void {
    this.config = {}
  }

  fetchContract() {
    this.versionLoading = true;
    if (this.contractId.length > 0) {
      this.contractService.fetchContract(this.contractId).subscribe((res: { data: IContract; }) => {
        this.contract = res.data
        this.fetchContractURL();
        this.getVersions()
      })
    } else {
      this.toast.error('No contract found')
    }
  }

  fetchContractURL(url?: string, key?: string) {
    if (this.contractId.length > 0) {
      this.contractService.getContractURL(this.contractId).subscribe(async res => {
        switch ((this.contract.attachment as IAttachment)?.type) {
          case 'application/pdf':
            this.docFormat = 'pdf'
            break;
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            this.docFormat = 'docx'
            break;
          default:
            this.docFormat = 'docx'
        }
        let officeDocType = ''
        switch (this.docFormat) {
          case 'docx':
            officeDocType = 'word'
            break;
          case 'pdf':
            officeDocType = 'pdf'
            break;
          default:
            officeDocType = 'word'
        }
        url = url ? url : res.data.url
        key = key ? key : res.data.key
        if (res.success) {
          const tokenConfig: Partial<IConfig> = {
            document: {
              fileType: this.docFormat,
              key: this.contractId + "-" + key,
              title: this.contract.name,
              url: url,
              permissions: {
                chat: this.permissions.canEdit,
                comment: this.permissions.canComment,
                download: true,
                edit: this.permissions.canEdit,
                fillForms: true,
                modifyContentControl: true,
                modifyFilter: true,
                print: true,
                protect: true,
                review: this.permissions.canComment,
                reviewGroups: [],
                userInfoGroups: [],
              },
            },
            documentType: officeDocType,
            editorConfig: {
              user: {
                group: this.contractId,
                id: this.currentUser?.email,
                image: this.currentUser?.profileUrl,
                name: this.currentUser?.firstName + ' ' + this.currentUser?.lastName
              },
              embedded: {
                toolbarDocked: "top",
              },
              customization: {
                toolbarHideFileName: true,
                hideRightMenu: true,
                compactToolbar: true,
                compactHeader: true,
                comments: true,
                forcesave: true,
                uiTheme: "theme-gray",
                logo: {
                  image: "https://arena-localhost-public.s3.ap-south-1.amazonaws.com/uploads/3.png",
                }
              },
              callbackUrl: environment.apiUrl + '/public/save-contract-document' + "?contractId=" + this.contractId + "&userId=" + this.currentUser?._id,
            },
            height: "100%",
            width: "100%",
          }
          const token = this.jwtService.signToken(tokenConfig, environment.jwtSecret);
          this.config = {
            ...tokenConfig,
            token: token
          };
        }
      });
    } else {
      this.toast.error('No document found , try uploading again !')
    }
  }

  saveVersion() {
    this.versionService.saveVersion(this.contract._id).subscribe(res => {
      if (res.success) {
        this.toast.success('Version Saved Successfully')
      }
    })
  }

  getVersions() {
    this.versionService.getVersions(this.contract._id).subscribe(res => {
      if (res.success) {
        this.versions = res.data
        this.filteredVersions = this.versions.slice(1)
        this.currentVersion = this.versions[0]
        this.versionLoading = false;
      }
    })
  }

  showVersion(item: IDocumentVersion) {
    this.selectedVersion = item
    if (item.file && item.file.url) {
      this.versionService.getDocVersionURL(this.contract._id, item.version).subscribe(res => {
        if (res.success) {
          this.fetchContractURL(res.data.url, res.data.key)
        }
      })

    }
  }

  downloadDocument(uint8Array: Uint8Array, fileName: string, mimeType?: string) {
    const blob: Blob = new Blob([new Uint8Array(uint8Array)], {
      type: mimeType ? mimeType : 'application/pdf'
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = mimeType ? `${fileName}.docx` : `${fileName}.pdf`;
    document.body.append(link);
    link.click();
    link.remove();
  }


  downloadVersion(versionId: string, index: number) {
    this.versionLoading = true;
    this.versionService.downloadVersion(this.contract._id, versionId).subscribe(res => {
      if (res.success) {
        this.downloadDocument(res.data.data, `${this.contract.name} - Version${this.versions.length - index}`, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        this.versionLoading = false;
      }
    })
  }

  deleteVersion(versionId: string) {
    this.versionService.deleteVersion(this.contract._id, versionId).subscribe(res => {
      if (res.success) {
        this.toast.success('Version Deleted Successfully')
        this.getVersions()
      }
    })
  }
}
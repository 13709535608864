import { Component, Input, OnInit } from '@angular/core';
import { ContractChecklistService, IGetContractChecklist } from '../../services/contract-checklist-item.service';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { CONTRACT_CHECKLIST_STATUS } from '../../../@core/models/enums';
import { toFixedNumber } from '../../../common/utils';
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts';
import { GAUGE_COLORS, setGuageChart } from '../../utils';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { ToastService } from '../../../@core/services/toast.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ChecklistsService } from '../../services/checklists.service';
import { FormsModule } from '@angular/forms';
import { IChecklistItem } from '../../models/settings.model';
import _ from 'lodash';
import { RiskMatrixComponent } from "../risk-matrix/risk-matrix.component";

const IMPACT_COLOR = {
  1: '#1EB768',
  2: '#B2D34A',
  3: '#F7A232',
  4: '#E86829',
  5: '#DC3622'
}

@Component({
  selector: 'app-check-points',
  standalone: true,
  imports: [CommonModule, ButtonModule, NgxEchartsModule, TooltipModule, AccordionModule, CardModule, DialogModule, RadioButtonModule, FormsModule, RiskMatrixComponent],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useValue: {
        echarts: () => import('echarts')
      }
    }
  ],
  templateUrl: './check-points.component.html',
  styleUrl: './check-points.component.scss'
})
export class CheckPointsComponent implements OnInit {

  @Input() contractId: string = "";
  checklists: IGetContractChecklist[] = []
  contractChecklistStatus = CONTRACT_CHECKLIST_STATUS
  statusCount: Record<CONTRACT_CHECKLIST_STATUS, number> = {
    [CONTRACT_CHECKLIST_STATUS.COMPLIANCE]: 0,
    [CONTRACT_CHECKLIST_STATUS.NOT_IN_COMPLIANCE]: 0,
    [CONTRACT_CHECKLIST_STATUS.SAVE_FOR_LATER]: 0
  }
  IMPACT_COLOR: { [key: number]: string } = IMPACT_COLOR;
  complianceRate: number = 0
  chartOption: echarts.EChartsOption = {}
  loading: boolean = true
  selectedChecklist: IChecklistItem['_id'] = '';
  sourceDialog: {
    visible: boolean,
    selectedSource: string,
    selectedRecommended: string
  } = { visible: false, selectedSource: '', selectedRecommended: '' };

  rulesDialog: {
    visible: boolean,
    checklistId: string | null,
    rules: { rule: string, impact: number }[],
    selectedRule: { rule: string, impact: number },
  } = { visible: false, checklistId: null, rules: [], selectedRule: { rule: '', impact: 0 } };

  riskMatrixDialog: {
    visible: boolean,
    selectedImpact: number,
    selectedProbability: number
  } = { visible: false, selectedImpact: 0, selectedProbability: 0 };

  constructor(private contractChecklistService: ContractChecklistService, private toastService: ToastService, private checklistService: ChecklistsService) {

  }

  ngOnInit(): void {
    this.loading = true
    this.contractChecklistService.extractChecklistItemsFromContract(this.contractId).subscribe(res => {
      if (res.success) {
        this.getContractChecklistMappings()
      }
    })
  }

  getContractChecklistMappings() {
    this.contractChecklistService.getContractChecklistMappings(this.contractId).subscribe(res => {
      if (res.success && res.data) {
        this.loading = false
        this.checklists = res.data
        let totalMappingsCount = 0
        this.statusCount = {
          [CONTRACT_CHECKLIST_STATUS.COMPLIANCE]: 0,
          [CONTRACT_CHECKLIST_STATUS.NOT_IN_COMPLIANCE]: 0,
          [CONTRACT_CHECKLIST_STATUS.SAVE_FOR_LATER]: 0
        }
        for (const checklist of this.checklists) {
          if (checklist.status) {
            this.statusCount[checklist.status]++;
            totalMappingsCount++;
          }
        }
        this.complianceRate = totalMappingsCount ? toFixedNumber((this.statusCount[CONTRACT_CHECKLIST_STATUS.COMPLIANCE] / totalMappingsCount) * 100, 2) : 0
        this.chartOption = setGuageChart(this.complianceRate, [GAUGE_COLORS.RED, GAUGE_COLORS.YELLOW, GAUGE_COLORS.GREEN])
      }
    })
  }

  getChecklistRules() {
    this.checklistService.getRulesLinkedToChecklist(this.selectedChecklist).subscribe((res) => {
      if (res && res.success) {
        this.rulesDialog.rules = res.data.impactRules;
        const currentRule = this.rulesDialog.rules.find(rule =>
          rule.rule === this.rulesDialog.selectedRule?.rule &&
          rule.impact === this.rulesDialog.selectedRule?.impact
        );
        if (currentRule) {
          this.rulesDialog.selectedRule = currentRule;
        }
        this.riskMatrixDialog.selectedImpact = this.riskMatrixDialog.selectedImpact ? this.riskMatrixDialog.selectedImpact : res.data.impact;
        this.riskMatrixDialog.selectedProbability = res.data.probability;
      } else {
        this.toastService.error('Error fetching the rules');
      }
    });
  }

  changeRule() {
    const data = { contract: this.contractId, checklistItem: this.selectedChecklist, impactRule: this.rulesDialog.selectedRule };
    this.contractChecklistService.updateContractChecklistMapping(data).subscribe(res => {
      if (res && res.success) {
        this.toastService.success('Successfully updated the impact rule for the selected checklist.');
      } else {
        this.toastService.error('Error updating the impact rule for the selected checklist.');
      }
      this.closeRulesDialog();
    })
  }

  closeRulesDialog = _.debounce(() => {
    this.rulesDialog.visible = false;
    this.getContractChecklistMappings();
  }, 500)

  mappingChanged(checklist: IGetContractChecklist, status: CONTRACT_CHECKLIST_STATUS) {
    this.contractChecklistService.saveContractChecklistMapping({ contract: this.contractId, checklistItem: checklist._id, status }).subscribe(res => {
      if (res.success) {
        this.getContractChecklistMappings()
      }
    })
  }

  openChangeRuleDialog(data: { checklist: IChecklistItem['_id'], impactRule?: { rule: string, impact: number } }) {
    this.selectedChecklist = data.checklist;
    this.rulesDialog.visible = true;
    this.rulesDialog.selectedRule = data.impactRule || { rule: '', impact: 0 };
    this.getChecklistRules();
  }

  openSourceDialog(checklist: { source: string, recommended?: string }) {
    this.sourceDialog.selectedSource = checklist.source;
    this.sourceDialog.selectedRecommended = checklist.recommended || '';
    this.sourceDialog.visible = true;
  }

  openRiskMatrixDialog(data: { checklist: IChecklistItem['_id'], impactRule?: { rule: string, impact: number } }) {
    this.selectedChecklist = data.checklist;
    this.riskMatrixDialog.visible = true;
    this.riskMatrixDialog.selectedImpact = data.impactRule?.impact || 0;
    this.getChecklistRules();
  }

  copyToClipboard() {
    const text = this.sourceDialog.selectedRecommended;
    navigator.clipboard.writeText(text);
    this.toastService.success('Copied to clipboard');
  }
}

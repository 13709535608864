import { Component, Input } from '@angular/core';
import { FormatTextPipe } from '../../../../../../@shared/pipes/formated-text.pipe';
import { MarkdownModule } from 'ngx-markdown';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-text',
  standalone: true,
  imports: [FormatTextPipe, MarkdownModule, TooltipModule],
  templateUrl: './text.component.html',
  styleUrl: './text.component.scss'
})
export class TextComponent {
  @Input() public text: string = '';

  constructor() { }

  ngOnInit(): void {
  }
}

@if (currentScreenComponent){
<div class="flex flex-row gap-2 font-semibold h-[5%]">
    <p-button label="Go Back" icon="pi pi-angle-left" (click)="goBack()" styleClass="text-sm"></p-button>
</div>
}
<div class="h-[95%]">
    @switch (currentScreenComponent) {
    @case (CURRENT_SCREEN_WINDOW.TRANSALTE) {
    <app-translate [contractId]="contractId"></app-translate>
    }
    @case(CURRENT_SCREEN_WINDOW.CHECKPOINTS) {
    <app-check-points [contractId]="contractId"></app-check-points>
    }
    @case (CURRENT_SCREEN_WINDOW.SUMMARY) {
    <app-summary [contractId]="contractId"></app-summary>
    }

    @case (CURRENT_SCREEN_WINDOW.KEY_HIGHLIGHTS) {
    <app-key-highlights [contractId]="contractId" [documentInSync]="documentInSync" />
    }

    @default {
    <div class="w-full flex flex-col p-1.5">
        <div class="flex flex-row justify-between items-center">
            @if(isRefreshing && refreshingUser) {
            <div class="text-sm text-gray-500 mr-3 mt-1"> Currently generating insights by {{refreshingUser}}
            </div>
            }
            @else if(latestInsightDetails) {
            <div class="text-sm text-gray-500 mr-3 mt-1">Insights last generated by
                {{latestInsightDetails.username}} on {{latestInsightDetails.updatedAt| date:'mediumDate'}} at
                {{latestInsightDetails.updatedAt| date:'shortTime'}}
            </div>
            }
            <div class="ml-auto">
                <p-button (click)="generateInsights()" label="Refresh" [loading]="isRefreshing"
                    [disabled]="isRefreshing" loadingIcon="pi pi-refresh" icon="pi pi-refresh" severity="primary"
                    styleClass="w-100 bg-white p-2 px-4 mt-2 rounded-xl" [outlined]="true" />
            </div>
        </div>
        @if(isRefreshing) {
        <div class="flex flex-col">
            <div class="p-2 animate-pulse">
                <div class="col-span-2 flex flex-col justify-between p-12 bg-gray-200 rounded-lg shadow-sm">
                </div>
            </div>

            <div class="p-2 animate-pulse">
                <div class="col-span-2 flex flex-col justify-between p-24 bg-gray-200 rounded-lg shadow-sm">
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 p-2 animate-pulse">
                <div class="flex flex-col justify-between p-12  bg-gray-200 rounded-lg shadow-sm">
                </div>

                <div class="flex flex-col justify-between p-12  bg-gray-200 0 rounded-lg shadow-sm">
                </div>

                <div class="col-span-2 flex flex-col justify-between p-12 bg-gray-200 rounded-lg shadow-sm">
                </div>
                <div class="col-span-2 flex flex-col justify-between p-12 bg-gray-200 rounded-lg shadow-sm">
                </div>
            </div>
        </div>

        }
        @else{


        <div class="border shadow rounded-lg mt-2 p-2">
            <div class="flex flex-row items-center">
                <div echarts [options]="chartOption" class="guage-chart mr-3"></div>
                <div>
                    <h2 class="font-semibold text-base ">Risk Score</h2>
                    <p class="text-sm font-medium">This document has {{riskScore}}% of risk as per the analysis</p>
                </div>
            </div>
        </div>

        <div class="rounded-lg bg-arena-orange-100 mt-3 p-4">
            <div class="flex items-center justify-between">
                <h2 class="font-semibold text-base">Tags</h2>
                <span
                    class="group flex items-center cursor-pointer px-2 py-1 rounded-lg transition-all duration-300 ease-in-out  hover:bg-orange-500 hover:text-white active:scale-95 active:bg-orange-600 active:transform"
                    (click)="openComponent(CURRENT_SCREEN_WINDOW.KEY_HIGHLIGHTS)">
                    <p class="text-sm">View all</p>
                    <i class="pi pi-angle-right cursor-pointer "></i>
                </span>
            </div>
            @if (tagsLoading) {
            <div class="grid grid-cols-2 gap-4">
                <div *ngFor="let _ of [1, 2, 3]" class="mb-1 col-span-2 animate-pulse">
                    <div class="flex flex-row justify-between items-center">
                        <div class="flex flex-col gap-2">
                            <div class="block h-4 w-32 bg-gray-300 rounded"></div>
                            <div class="flex flex-row gap-2 items-center">
                                <div class="relative inline-flex items-center">
                                    <div class="h-6 w-24 bg-gray-300 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row w-full justify-between items-center mt-2">
                        <div class="h-10 bg-gray-300 w-3/4 rounded-lg"></div>
                        <div class="h-10 w-16 bg-gray-300 rounded-xl"></div>
                    </div>
                </div>
            </div>
            }
            @else{
            <form class="p-3">
                <div class="grid grid-cols-2 gap-4">
                    @for(mapping of contractTagMappings; track mapping.tagTypeId;let i = $index) {
                    <div class="col-span-2">
                        <div class="flex flex-row justify-between items-center">
                            <div class="flex flex-col gap-1">
                                <label for="location"
                                    class="block text-sm font-bold text-gray-700 mr-2 whitespace-nowrap">{{mapping.tagTypeName}}
                                </label>
                                @for(tag of mapping.tags; track tag.tagId){

                                @if(tag.aiGenerated || tag.dataType != 'Custom'){
                                <div class="flex flex-row gap-1 items-center">
                                    <div class="relative inline-flex items-center text-nowrap">
                                        <app-data-type [dataType]="tag.dataType" [(value)]="tag.tagName"
                                            [onEdit]="onEditTag[i]"
                                            (onDelete)="deleteClicked(mapping.tagTypeId,tag.tagId)"
                                            (onValueChange)="changedValue=$event ;onEditTag[i]=true"></app-data-type>
                                        @if(tag.aiGenerated){
                                        <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top"
                                            class="absolute -top-1 -right-1 h-4 w-4 border border-arena-orange-300 rounded-full bg-white p-0.5 shadow"
                                            alt="Star icon">
                                        }
                                    </div>
                                    @if(onEditTag[i]){
                                    <div class="relative inline-flex items-center">
                                        <span (click)="suggestEdit(mapping.tagTypeId,tag.tagId,false)"
                                            class="status-button check-button mx-1  group cursor-pointer transition-all duration-300 ease-in-out active:scale-95  active:transform">
                                            <i class="pi pi-check small-icon"></i>
                                        </span>
                                        <span
                                            class="status-button cancel-button mx-1 group cursor-pointer transition-all duration-300 ease-in-out active:scale-95 active:transform"
                                            (click)="suggestEdit(mapping.tagTypeId,tag.tagId ,true)">
                                            <i class="pi pi-times small-icon"></i>
                                        </span>
                                    </div>
                                    }
                                </div>

                                }
                                @else {
                                <p-chip [label]="tag.tagName | truncate:15"
                                    styleClass="bg-arena-orange-300 text-xs chip-truncate text-nowrap"
                                    [removable]="mapping.tagTypeId == editTagTypeId"
                                    (onRemove)="deleteClicked(mapping.tagTypeId, tag.tagId)" [pTooltip]="tag.tagName"
                                    tooltipPosition="top">
                                </p-chip>

                                }

                                }

                                @if(!mapping.tags || mapping.tags.length == 0){
                                <span class="text-sm text-gray-400">No tags added</span>
                                }
                            </div>
                            @if(!documentInSync && mapping.tags?.length == 0){
                            <p-progressSpinner styleClass="my-4"
                                [style]="{width: '20px', height: '20px'}"></p-progressSpinner>
                            }
                            @else{
                            <i class="pi pi-pencil cursor-pointer small-icon"
                                (click)="editClicked(mapping.tagTypeId)"></i>
                            }
                        </div>
                        @if(mapping.tagTypeId == editTagTypeId) {

                        <div class="flex flex-row w-full justify-between items-center mt-2">
                            <p-autoComplete #autoComplete [suggestions]="tagSuggestions" optionLabel="name"
                                (onSelect)="onOptionSelect($event)" (click)="searchTags($event, mapping.tagTypeId)"
                                (onFocus)="autoComplete.show()" [panelStyle]="{width:'11rem'}"
                                [inputStyle]="{'width':'100%', 'height': '100%','padding-left' : '0.5rem'}"
                                (completeMethod)="searchTags($event, mapping.tagTypeId)" emptyMessage="No Tags Found"
                                class="w-full" styleClass="border rounded-lg " placeholder="Type here"></p-autoComplete>
                            <p-button label="Add" icon="pi pi-plus"
                                styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2" [rounded]="true"
                                [disabled]="!selectedTagId.length || mapping.tags?.length != 0"
                                (click)="addTagMapping(mapping.tagTypeId)"></p-button>
                        </div>
                        }
                    </div>
                    }
                </div>
            </form>
            }
        </div>

        <div class="flex gap-2 cursor-pointer mt-3">
            <div class="card border relative overflow-hidden rounded-lg flex"
                (click)="openComponent(CURRENT_SCREEN_WINDOW.TRANSALTE)">
                <img class="h-[13rem]  w-[9rem] absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] z-10 object-contain "
                    src="images/insights-image.png" alt="image">
                <div class="p-3">
                    <h2 class="text-base font-semibold ">AI Tooling</h2>
                    <p class="text-sm w-[7rem] "> Translate to simple language</p>
                </div>
            </div>

            <div class="relative w-full">
                <div class="card relative cursor-pointer" (click)="openComponent(CURRENT_SCREEN_WINDOW.CHECKPOINTS)">
                    <img class="h-[13rem] w-[9rem]  absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] z-10 object-contain"
                        src="images/checklists.png" alt="image">
                    <div class="card border rounded-lg flex z-10">
                        <div class="p-3">
                            <div class="flex flex-row items-center">
                                <h2 class="font-semibold text-base mr-2">Checklist Items</h2>
                            </div>

                            <p class="text-sm w-[7rem] ">Your Documents Instantly</p>
                        </div>
                    </div>
                </div>
                <p-badge *ngIf="checklistCount" class="absolute top-[-0.35rem] right-[-0.35rem]"
                    [value]="checklistCount"></p-badge>
            </div>
        </div>

        <div class="summary-card mt-3  relative w-full overflow-hidden">
            <div class="summary-card  cursor-pointer border rounded-lg "
                (click)="openComponent(CURRENT_SCREEN_WINDOW.SUMMARY)">
                <div class="p-3">
                    <h2 class="font-semibold text-base ">Summary</h2>
                    <p class="text-sm w-[10rem] "> Enter a brief summary of your document</p>
                    <img class="h-[13rem] w-[9rem] absolute bottom-4 right-[3%] translate-y-[50%] z-10 object-contain"
                        src="images/summary-image.png" alt="image">
                </div>
            </div>
        </div>
        <div class="summary-card mt-3  relative w-full overflow-hidden" (click)="openCompareDialog()">
            <div class="summary-card  cursor-pointer border rounded-lg ">
                <div class="p-3">
                    <h2 class="font-semibold text-base ">Compare</h2>
                    <p class="text-sm w-[10rem] "> Compare two documents using AI.</p>
                    <img class="h-[13rem] w-[9rem] absolute bottom-4 right-[3%] translate-y-[50%] z-10 object-contain"
                        src="images/insights-image.png" alt="image">
                </div>
            </div>
        </div>
        }
    </div>
    }
    }
</div>
<div class="flex flex-1 flex-col gap-2 p-6 bg-white" [style.minHeight]="'100vh'">
    <!-- header -->
    <div class="flex justify-between items-center">
        <span class="font-semibold text-xl theme-text">Compare Documents</span>
        <i class="pi pi-sync cursor-pointer theme-text" (click)="changeCompareDocument()"></i>
    </div>
    <!-- body -->
    @if(loading) {
    <div class="flex flex-row gap-4">
        <p-skeleton height="42vh" width="48vw">
        </p-skeleton>
        <p-skeleton height="42vh" width="48vw">
        </p-skeleton>
    </div>
    <p-skeleton height="45vh" width="97vw">
    </p-skeleton>
    }@else {
    @if(document || comparedDocument) {
    <div class="flex flex-col">
        <div class="flex gap-4">
            <div class="w-2/3  rounded-lg p-4 bg-white drafted-card">
                <ng-container *ngTemplateOutlet="documentTemplate; context: { document: document }"></ng-container>
            </div>
            <div class="w-2/3  rounded-lg p-4 bg-white drafted-card">
                <ng-container
                    *ngTemplateOutlet="documentTemplate; context: { document: comparedDocument }"></ng-container>
            </div>
        </div>
        <div class="flex justify-center mt-4">
            <button (click)="isExpanded = !isExpanded"
                class="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-800">
                {{ isExpanded ? 'Show Less' : 'Show More' }}
            </button>
        </div>
    </div>
    }
    @if(compareResult) {
    <div>
        <span class="font-bold text-xl">Compare Result</span>
        <div class=" w-full flex overflow-auto">
            <markdown class="w-2/3" [data]=" compareResult"></markdown>
            <div class="w-1/3  border rounded-xl">
                <app-chat [contractId]="document._id" [compareDocumentId]="comparedDocument._id" [compare]="true"
                    [suggestions]="suggestions" [docModel]="compareType"></app-chat>
            </div>
        </div>
    </div>
    }
    }
</div>

<ng-template #documentTemplate let-document="document">
    <div class=" flex flex-col group">
        <div class="p-4">
            <div class="flex justify-between">
                <div class="flex justify-center align-center gap-3">
                    <img src="/icons/home-card-icon.svg" alt="icon">
                </div>
                <div class="flex items-center justify-between">
                    <app-badge [contract]="document" />
                </div>
            </div>
            <h2 class="font-bold text-lg pt-2">{{document.name | truncate:30 }}</h2>
            @if(document.summary){
            <markdown class="text-justify" [class.line-clamp-2]="!isExpanded" [data]="document.summary"></markdown>
            }@else {
            <p class="font-medium pt-2">
                <span class="text-gray-400">No summary available</span>
            </p>
            }
        </div>
        <div class="flex flex-1 items-end m-2">
            <div class="w-full">
                <hr>
                <div class="edited-status d-flex">
                    <p><i class="pi pi-sync text-sm"></i> Last edited on <span class="font-bold text-sm">
                            {{document.updatedAt| date:'mediumDate'}}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ToastService } from '../../../@core/services/toast.service';
import { ContractService } from '../../../contract-management/services/contracts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-ai-assistant',
  standalone: true,
  imports: [DialogModule, FormsModule, ButtonModule],
  templateUrl: './ai-assistant.component.html',
  styleUrl: './ai-assistant.component.scss'
})
export class AiAssistantComponent implements OnInit{

  @Input() visible: boolean = false
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onDialogClose = new EventEmitter<void>();
  @Output() onGenerate = new EventEmitter<string>();

  docId!: string

  content: string = ''
  response: string = ''
  loading: boolean = false
  wordCount: number = 0
  wordLimit: number = 500

  public promptQuestions = [
    'Create a contract for general contractor services',
    'Draft a construction project subcontractor agreement',
    'Write a contract for a construction site inspection service'
  ]

  constructor(private toastService: ToastService, private contractService: ContractService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.docId = this.activatedRoute.snapshot.queryParams['documentId']
  }

  ngOnInit() {
    
  }

  openAIAssistant() {
    this.visible = true;
  }

  cancel() {
    this.resetAiAssistant();
    this.visibleChange.emit(this.visible);
    this.onDialogClose.emit();
  }

  resetAiAssistant() {
    this.content = '';
    this.response = '';
    this.loading = false;
  }


  callAIAssistant() {
    this.loading = true
    this.toastService.info('Generating your document, please wait...')
    this.contractService.generateContractWithAi(this.content, undefined, this.docId).subscribe({
      next: res => {
        this.loading = false
        this.router.navigate(['/edit-document'], { queryParams: { documentId: res.data } });
        queueMicrotask(() => {
          window.location.reload()
        })
      },
      error: _ => this.loading = false
    })
  }

  checkWordLimit() {
    this.wordCount = this.getWordCount(this.content);

    if (this.wordCount > this.wordLimit) {
      const wordsArray = this.content.split(/\s+/).slice(0, this.wordLimit);
      this.content = wordsArray.join(' ');
    }
  }

  openDocument(documentId: string) {
    window.location.href = `/edit-document?documentId=${documentId}`;
  }

  getWordCount(content: string): number {
    if (!content) return 0;
    return content.trim().split(/\s+/).length;
  }

  sendPrompt(prompt: string) {
    this.content = prompt
    this.checkWordLimit()
  }
}

@if(loading) {
<div class="grid grid-cols-2 gap-4">
    <div *ngFor="let _ of [1, 2, 3, 4, 5]" class="mb-1 col-span-2 animate-pulse">
        <div class="h-16 bg-gray-300 w-full rounded-lg mt-2"></div>
    </div>
</div>
}
@else {
@if(!checklists.length) {
<div class="flex flex-col items-center">
    <img class="my-5 h-[6rem] w-[8.75rem]" src="images/checklist-mappings-empty.png">
    <span class="text-lg font-semibold mt-5">Checklist Items</span>
    <p class="text-center text-sm text-gray-500">Checklist items will be shown here when they are added to the document
    </p>
</div>
}
@else {
<div class="flex flex-col">
    <span class="font-semibold text-lg mt-3">Checklist Items</span>
    <div class="border shadow rounded-lg p-2 my-3">
        <div class="flex flex-row items-center">
            <div echarts [options]="chartOption" class="gauge-chart mr-3"></div>
            <div>
                <h2 class="font-semibold text-base ">Compliance Rate</h2>
                <p class="text-sm font-medium">This document complies with {{complianceRate}}% of the required
                    checklists</p>
            </div>
        </div>
    </div>
    <p class="text-sm text-gray-500">Following are the brief checklist items that can be checked
        within the
        document</p>
    <div class="flex flex-row justify-between items-center mt-5">
        <div class="flex flex-row items-center">
            <i class="pi pi-list mr-2"></i>
            <span class="font-semibold">List of contents
                ({{statusCount[contractChecklistStatus.COMPLIANCE]}}/{{checklists.length}})</span>
        </div>
        <div class="flex flex-row items-center">
            <div class="status-button check-button mr-2">
                <i class="pi pi-check mr-1 small-icon"></i>{{statusCount[contractChecklistStatus.COMPLIANCE]}}
            </div>
            <div class="status-button cancel-button mr-2">
                <i class="pi pi-times mr-1 small-icon"></i>{{statusCount[contractChecklistStatus.NOT_IN_COMPLIANCE]}}
            </div>
            <div class="status-button bookmark-button">
                <i class="pi pi-bookmark mr-1 small-icon"></i>{{statusCount[contractChecklistStatus.SAVE_FOR_LATER]}}
            </div>
        </div>
    </div>

    <div class="flex flex-col mt-3 max-h-[50vh] overflow-y-scroll">
        @for(checklist of checklists; track checklist._id) {
        @if(checklist.status !== contractChecklistStatus.NOT_IN_COMPLIANCE && !checklist.aiGenerated) {
        <div class="mb-4 p-3 border shadow-sm rounded-lg flex flex-row justify-between items-center">
            <span class="text-sm overflow-ellipsis" pTooltip="{{checklist.name}}" tooltipPosition="bottom">
                {{checklist.name}}
            </span>
            <div class="flex flex-row items-center gap-2 mr-5">
                <div class="status-button check-button mx-2 cursor-pointer" [ngClass]="{'active': true }"
                    (click)="mappingChanged(checklist, contractChecklistStatus.COMPLIANCE)">
                    <i class="pi pi-check small-icon"></i>
                </div>
                <div class="status-button cancel-button mr-2 cursor-pointer" [ngClass]="{'active': false }"
                    (click)="mappingChanged(checklist, contractChecklistStatus.NOT_IN_COMPLIANCE)"><i
                        class="pi pi-times small-icon"></i></div>
                <div class="status-button bookmark-button cursor-pointer"
                    [ngClass]="{'active': checklist.status == contractChecklistStatus.SAVE_FOR_LATER }"
                    (click)="mappingChanged(checklist, contractChecklistStatus.SAVE_FOR_LATER)">
                    <i [ngClass]="checklist.status == contractChecklistStatus.SAVE_FOR_LATER ? 'pi pi-bookmark-fill' : 'pi pi-bookmark'"
                        class="small-icon"></i>
                </div>
            </div>
        </div>
        } @else {
        <p-accordion class="mb-4">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="w-full mr-3 flex flex-row ml-3 mb-1 justify-between items-end">
                        <span class="text-sm mr-4 overflow-ellipsis" pTooltip="{{checklist.name}}"
                            tooltipPosition="bottom">{{checklist.name}}</span>
                        <div class="flex flex-row items-center gap-2">
                            <div class="relative">
                                @if(checklist.aiGenerated && checklist.status == contractChecklistStatus.COMPLIANCE) {
                                <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top"
                                    class="absolute -top-1 -right-1 h-4 w-4 border border-arena-orange-300 rounded-full bg-white p-0.5 shadow"
                                    alt="Star icon">
                                }
                                <div class="status-button check-button mx-2 cursor-pointer"
                                    [ngClass]="{'active': checklist.status == contractChecklistStatus.COMPLIANCE }"
                                    (click)="mappingChanged(checklist, contractChecklistStatus.COMPLIANCE)">
                                    <i class="pi pi-check small-icon"></i>
                                </div>

                            </div>

                            <div class="relative">
                                @if(checklist.aiGenerated && checklist.status ==
                                contractChecklistStatus.NOT_IN_COMPLIANCE) {
                                <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top"
                                    class="absolute -top-1 -right-1 h-4 w-4 border border-arena-orange-300 rounded-full bg-white p-0.5 shadow"
                                    alt="Star icon">
                                }
                                <div class="status-button cancel-button mr-2 cursor-pointer"
                                    [ngClass]="{'active': checklist.status == contractChecklistStatus.NOT_IN_COMPLIANCE }"
                                    (click)="mappingChanged(checklist, contractChecklistStatus.NOT_IN_COMPLIANCE)"><i
                                        class="pi pi-times small-icon"></i></div>

                            </div>
                            <div class="status-button bookmark-button cursor-pointer"
                                [ngClass]="{'active': checklist.status == contractChecklistStatus.SAVE_FOR_LATER }"
                                (click)="mappingChanged(checklist, contractChecklistStatus.SAVE_FOR_LATER)"><i
                                    [ngClass]="checklist.status == contractChecklistStatus.SAVE_FOR_LATER ? 'pi pi-bookmark-fill' : 'pi pi-bookmark'"
                                    class="small-icon"></i>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="flex flex-col gap-y-2">
                    @if(checklist.status === this.contractChecklistStatus.NOT_IN_COMPLIANCE) {
                    <div class="flex justify-end">
                        <p-button
                            (click)="openRiskMatrixDialog({checklist: checklist._id, impactRule: checklist.impactRule})"
                            styleClass="w-100 bg-arena-orange p-1 px-3 rounded-xl text-white text-sm"
                            label="Access Risk Matrix" />
                    </div>
                    @if(checklist.impactRule?.rule) {
                    <div class="line-clamp-2 text-ellipsis">
                        <span>Rule Applied: </span>
                        <span class="text-gray-500" pTooltip="{{checklist.impactRule?.rule}}"
                            tooltipPosition="top">{{checklist.impactRule?.rule}}</span>
                    </div>
                    <span (click)="openChangeRuleDialog({checklist: checklist._id, impactRule: checklist.impactRule})"
                        class="mt-[-4px] underline text-arena-orange cursor-pointer">Change Rule</span>
                    }
                    }
                    @if(checklist.aiGenerated) {
                    <div class="py-2 flex flex-row overflow-x-scroll items-center">
                        <span>Sources: </span>
                        @for (source of checklist.source; track $index) {
                        <span (click)="openSourceDialog({source: source.source, recommended: source.recommended})"
                            class="ml-3 cursor-pointer rounded-full bg-orange-500 text-white px-2 py-1 text-xs">{{$index
                            + 1}}</span>
                        }
                    </div>
                    }
                </div>
            </p-accordionTab>
        </p-accordion>
        }
        }
    </div>
</div>
}
}

<p-dialog [(visible)]="sourceDialog.visible" [header]="'Header'" [style]="{width: '40vw'}" [modal]="true">
    <ng-template pTemplate="header">
        <div class="font-bold text-lg text-arena-orange">Source</div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <p class="font-semibold">Source Text</p>
                <div class="p-3 bg-gray-200 rounded-lg text-sm">{{sourceDialog.selectedSource}}</div>
            </div>
            @if(sourceDialog.selectedRecommended) {
            <div class="flex flex-col gap-2">
                <p class="font-semibold">Recommended Text</p>
                <div class="flex p-3 bg-gray-200 rounded-lg text-sm">
                    <span class="flex-1">{{sourceDialog.selectedRecommended}}</span>
                    <i class="pi pi-copy cursor-pointer" (click)="copyToClipboard()"></i>
                </div>
            </div>
            }
        </div>
    </ng-template>
</p-dialog>


<p-dialog [(visible)]="rulesDialog.visible" [header]="'Header'" [style]="{width: '40vw'}" [modal]="true">
    <ng-template pTemplate="header">
        <div class="font-bold">Change Rule</div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col gap-2">
            @for(rule of rulesDialog.rules; track rule; let i = $index) {
            <div class="flex flex-row gap-4">
                <p-radioButton [name]="'ruleSelection'" [value]="rule" [(ngModel)]="rulesDialog.selectedRule"
                    (onClick)="changeRule()"></p-radioButton>
                <span class="inline-flex items-center justify-center rounded-md p-2 text-white"
                    [style]="{backgroundColor: IMPACT_COLOR[i+1], width: '2rem', height: '2rem'}">
                    {{i+1}}
                </span>
                <input readonly value={{rulesDialog.rules[$index].rule}}
                    class="p-2 rule w-full border border-gray-200 rounded-lg text-sm" rows="1" />
            </div>
            }
        </div>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="riskMatrixDialog.visible" [header]="'Risk Matrix'" [style]="{width: '72vw'}" [modal]="true">
    <app-risk-matrix [impactInput]="riskMatrixDialog.selectedImpact"
        [probabilityInput]="riskMatrixDialog.selectedProbability"></app-risk-matrix>
</p-dialog>
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { ContractTagService, IGetContractTagMappings } from '../../../../services/contract-tag.service';
import { ChipModule } from 'primeng/chip';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { TruncatePipe } from "../../../../../@shared/pipes/truncate.pipe";
import { DataTypeComponent } from "./components/data-type/data-type.component";
import { SpeedDial, SpeedDialModule } from 'primeng/speeddial';
import { CommonModule } from '@angular/common';
import { TAG_TYPES } from '../../../../models/settings.model';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ContractService } from '../../../../services/contracts.service';
import { ContractInsightsService } from '../../../../services/contract-insights.service';
import { SOCKET_EVENTS, SocketService } from '../../../../../@core/services/socket.service';
import { ToastService } from '../../../../../@core/services/toast.service';

@Component({
  selector: 'app-key-highlights',
  standalone: true,
  imports: [
    ChipModule,
    FormsModule,
    ButtonModule,
    AutoCompleteModule,
    TooltipModule,
    TruncatePipe,
    DataTypeComponent,
    SpeedDialModule,
    CommonModule,
    ProgressSpinnerModule
  ],
  templateUrl: './key-highlights.component.html',
  styleUrl: './key-highlights.component.scss'
})
export class KeyHighlightsComponent implements OnChanges, OnDestroy {

  @Input() contractId: string = "";
  @Input() documentInSync: boolean = false;

  contractTagMappings: IGetContractTagMappings[] = []
  tagSuggestions: { name: string, _id: string }[] = []
  editTagTypeId: string = ''
  selectedTagId: string = ''
  changedValue: string = ''
  isEditing = false;
  loading = false;
  suggestEditItems =
    [{
      icon: 'pi pi-check',
      command: () => {

      }
    },
    {
      icon: 'pi pi-times',
      command: () => {

      }
    }]
  onEditTag: boolean[] = []
  extractEntitiesSubscription: any

  constructor(private contractTagService: ContractTagService,
    private contractInsightsService: ContractInsightsService,
    private socketService: SocketService,
    private toast: ToastService,
  ) {
    this.extractEntitiesSubscription = this.socketService.on(SOCKET_EVENTS.CONTRACT_TAG_MAPPING).subscribe((result: any) => {
      this.updateMappings(result[0])
    });
  }

  ngOnChanges() {
    this.getTagMappings()
  }

  ngOnDestroy(): void {
    if (this.extractEntitiesSubscription) {
      this.extractEntitiesSubscription.unsubscribe()
    }
  }

  getTagMappings() {
    this.loading = true
    if (!this.documentInSync) {
      this.contractInsightsService.extractAllTags(this.contractId).subscribe(res => { })
    }
    this.getMappings()
  }

  getMappings() {
    this.contractTagService.getContractTagMappings(this.contractId).subscribe(res => {
      if (res.success && res.data) {
        this.contractTagMappings = res.data.mappings
        this.onEditTag = new Array(res.data.mappings.length).fill(false)
        this.loading = false
      }
    })
  }

  updateMappings(data: { mappings: IGetContractTagMappings[], documentInSync: boolean }) {
    this.contractTagMappings = data.mappings
    this.onEditTag = new Array(data.mappings.length).fill(false)
  }

  deleteClicked(tagTypeId: string, tagId: string) {
    this.contractTagService.removeContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: tagId }).subscribe(res => {
      if (res.success) {
        this.getTagMappings()
      }
    })
  }


  editClicked(tagTypeId: string) {
    this.selectedTagId = ''
    this.editTagTypeId = this.editTagTypeId == tagTypeId ? '' : tagTypeId
    let i = this.contractTagMappings.findIndex(tag => tag.tagTypeId == tagTypeId)
    if (this.contractTagMappings[i].dataType == 'Custom') {
      return
    }
    this.contractTagMappings[i].tags?.forEach(tag => {
      if (tag.aiGenerated || tag.dataType != 'Custom') {
        this.editTagTypeId = ''
      }
    })
    this.onEditTag[i] = !this.onEditTag[i]
  }

  onOptionSelect(event: AutoCompleteSelectEvent) {
    this.selectedTagId = event.value._id
  }

  addTagMapping(tagTypeId: string) {
    this.contractTagService.addContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: this.selectedTagId }).subscribe(res => {
      if (res.success) {
        this.selectedTagId = ''
        this.editTagTypeId = ''
        this.getTagMappings()
      }
    })
  }

  searchTags(event: any, tagTypeId: string) {
    this.contractTagService.getTagsBasedOnSearchValue({ contract: this.contractId, tagType: tagTypeId, searchValue: event.query }).subscribe(res => {
      if (res.success && res.data) {
        this.tagSuggestions = res.data.map((tag: { _id: string; name: string }) => ({
          ...tag,
          name: this.trimLetters(tag.name, 20)
        }));
      }
    })
  }

  trimLetters(text: string, charLimit: number): string {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + '...';
    }
    return text;
  }


  onEdit() {
    this.isEditing = true;
  }

  cancelEdit() {
    this.isEditing = false;
  }

  saveEdit() {
    this.isEditing = false;
  }

  suggestEdit(tagTypeId: string, tagId: string, close: boolean) {
    if (close) {
      this.contractTagMappings = []
      this.editTagTypeId = ''
      this.changedValue = ''
      this.loading = true
      this.contractTagService.getContractTagMappings(this.contractId).subscribe(res => {
        if (res.success && res.data) {
          this.contractTagMappings = res.data.mappings
          this.onEditTag = new Array(res.data.mappings.length).fill(false)
          this.loading = false
        }
      })
      return
    }
    this.contractTagService.addAItagToContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: tagId, tagName: this.changedValue }).subscribe(res => {
      if (res.success) {
        this.loading = true
        this.contractTagService.getContractTagMappings(this.contractId).subscribe(res => {
          if (res.success && res.data) {
            this.contractTagMappings = res.data.mappings
            this.onEditTag = new Array(res.data.mappings.length).fill(false)
            this.loading = false
          }
        })
      }
    })
  }


}

<div class="p-3">
    <span class="text-md font-bold">Tags</span>
</div>

@if (loading) {
<div class="grid grid-cols-2 gap-4">
    <div *ngFor="let _ of [1, 2, 3, 4, 5]" class="mb-1 col-span-2 animate-pulse">
        <div class="flex flex-row justify-between items-center">
            <div class="flex flex-col gap-2">
                <div class="block h-4 w-32 bg-gray-300 rounded"></div>
                <div class="flex flex-row gap-2 items-center">
                    <div class="relative inline-flex items-center">
                        <div class="h-6 w-24 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full justify-between items-center mt-2">
            <div class="h-10 bg-gray-300 w-3/4 rounded-lg"></div>
            <div class="h-10 w-16 bg-gray-300 rounded-xl"></div>
        </div>
    </div>
</div>
}@else {
<form class="p-3">
    <div class="grid grid-cols-2 gap-4">
        @for(mapping of contractTagMappings; track mapping.tagTypeId;let i = $index) {
        <div class="mb-1 col-span-2">
            <div class="flex flex-row justify-between items-center">
                <div class="flex flex-col gap-2">
                    <label for="location"
                        class="block text-sm font-bold text-gray-700 mr-2 whitespace-nowrap">{{mapping.tagTypeName}}
                    </label>
                    @for(tag of mapping.tags; track tag.tagId){

                    @if(tag.aiGenerated || tag.dataType != 'Custom'){
                    <div class="flex flex-row gap-2 items-center">
                        <div class="relative inline-flex items-center text-nowrap">
                            <app-data-type [dataType]="tag.dataType" [(value)]="tag.tagName" [onEdit]="onEditTag[i]"
                                (onDelete)="deleteClicked(mapping.tagTypeId,tag.tagId)"
                                (onValueChange)="changedValue=$event ;onEditTag[i]=true"></app-data-type>
                            @if(tag.aiGenerated){
                            <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top"
                                class="absolute -top-1 -right-1 h-4 w-4 border border-arena-orange-300 rounded-full bg-white p-0.5 shadow"
                                alt="Star icon">
                            }
                        </div>
                        @if(onEditTag[i]){
                        <div class="relative inline-flex items-center">
                            <span (click)="suggestEdit(mapping.tagTypeId,tag.tagId,false)"
                                class="status-button check-button mx-1  group cursor-pointer transition-all duration-300 ease-in-out active:scale-95  active:transform">
                                <i class="pi pi-check small-icon"></i>
                            </span>
                            <span
                                class="status-button cancel-button mx-1 group cursor-pointer transition-all duration-300 ease-in-out active:scale-95 active:transform"
                                (click)="suggestEdit(mapping.tagTypeId,tag.tagId ,true)">
                                <i class="pi pi-times small-icon"></i>
                            </span>
                        </div>
                        }
                    </div>

                    }
                    @else {
                    <p-chip [label]="tag.tagName | truncate:15"
                        styleClass="bg-arena-orange-300 text-xs chip-truncate text-nowrap"
                        [removable]="mapping.tagTypeId == editTagTypeId"
                        (onRemove)="deleteClicked(mapping.tagTypeId, tag.tagId)" [pTooltip]="tag.tagName"
                        tooltipPosition="top">
                    </p-chip>

                    }

                    }

                    @if(!mapping.tags || mapping.tags.length == 0){
                    <span class="text-sm text-gray-400">No tags added</span>
                    }
                </div>

                @if(!documentInSync && mapping.tags?.length == 0){
                <p-progressSpinner styleClass="my-4" [style]="{width: '20px', height: '20px'}"></p-progressSpinner>
                }
                @else{
                <i class="pi pi-pencil cursor-pointer small-icon" (click)="editClicked(mapping.tagTypeId)"></i>
                }
            </div>
            @if(mapping.tagTypeId == editTagTypeId) {

            <div class="flex flex-row w-full justify-between items-center mt-2">
                <p-autoComplete #autoComplete [suggestions]="tagSuggestions" optionLabel="name"
                    (onSelect)="onOptionSelect($event)" (completeMethod)="searchTags($event, mapping.tagTypeId)"
                    (click)="searchTags($event, mapping.tagTypeId)" (onFocus)="autoComplete.show()" class="w-full"
                    [panelStyle]="{width:'11rem'}" , emptyMessage="No Tags Found"
                    [inputStyle]="{'width':'100%', 'height': '100%','padding-left' : '0.5rem'}"
                    styleClass="border rounded-lg " placeholder="Type here"></p-autoComplete>
                <p-button label="Add" icon="pi pi-plus" styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2"
                    [rounded]="true" [disabled]="!selectedTagId.length || mapping.tags?.length != 0"
                    (click)="addTagMapping(mapping.tagTypeId)"></p-button>
            </div>
            }
        </div>
        }
    </div>
</form>
}
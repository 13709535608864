import { Pipe, PipeTransform } from '@angular/core';

@Pipe({

  standalone: true ,
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit: number = 20, trail: string = '...'): string {
    if (!value) {return '';}
    if (value.length <= limit) {return value;}
    return value.substring(0, limit) + trail;
  }

}
